import React, { useState, useRef } from 'react';

import {initializeApp} from 'firebase/app';

import config from '../Firebase/config/firebase';

import { useAuthState } from 'react-firebase-hooks/auth';
import {getDatabase, ref, onValue, push} from "firebase/database";
import {getAuth} from "firebase/auth";

import Button from "@mui/material/Button";
import TextArea from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";

import { useIdleTimer } from 'react-idle-timer'

const app = initializeApp(config);
const db = getDatabase(app);
const auth = getAuth();

function Chatbox(props) {
    const { balance } = props;
    return (
        <div className="chatbox">
            <h3>Join the conversation</h3>
            <ChatRoom balance={balance} />
        </div>
    );
}

function ChatRoom(props) {

    var { balance } = props;
    const dummy = useRef();

    const [formValue, setFormValue] = useState('');
    const [messages, setMessages] = useState([]);
    const [messageCount, setMessageCount] = useState(0);
    const [idle, setIdle] = useState(false);

    const handleOnIdle = event => {
        setIdle(true);
    }

    const handleOnActive = () => {
        setIdle(false);
    }

    const handleOnAction = () => {
    }

    useIdleTimer({
        timeout: 1000 * 10,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        onAction: handleOnAction,
        debounce: 500
    })

    const chatRef = ref(db,"/chat");
    onValue(chatRef, (data) => {
        if(messageCount !== Object.values(data.val()).length ) {
            const lastMessages = Object.values(data.val()).slice(Object.values(data.val()).length-5, Object.values(data.val()).length);
            setMessageCount(Object.values(data.val()).length);
            setMessages(lastMessages);
            if(idle) {
                setIdle(false);
                new Notification('New chat message!');
            }
        }
    });

    const sendMessage = async (e) => {
        e.preventDefault();
        if(auth) {
            const {uid, photoURL} = auth.currentUser;

            push(chatRef, {
                text: formValue,
                uid,
                photoURL,
                balance
            });

            setFormValue('');
            dummy.current.scrollIntoView({ behavior: 'smooth' });
        }
    }

    if (("Notification" in window)) {
        Notification.requestPermission();
    }

    const [user] = useAuthState(auth);

    return (<>
        <main>
            {messages && messages.map((msg, key) => <ChatMessage key={key} message={msg} />)}
            <span ref={dummy}></span>
        </main>
        {user && (
            <form className="chatForm" onSubmit={sendMessage}>
                <TextArea className="chatInput" value={formValue} onChange={(e) => setFormValue(e.target.value)} placeholder="say something nice" />
                <Button className="chatSubmit" color="grey" variant="contained" size="small" type="submit" disabled={!formValue}>Send</Button>
            </form>
        )}
        {!user && (
            <p>Log in to send messages</p>
        )}
    </>)
}

function ChatMessage(props) {
    const { text, uid, photoURL, balance } = props.message;

    const [user] = useAuthState(auth);
    if(user) {
        var messageClass = uid === user.uid ? 'sent' : 'received';
    } else {
        messageClass = 'received';
    }

    return (<>
        <div className={`message ${messageClass}`} key={uid}>
            <Avatar
                alt={uid}
                src={photoURL}
                sx={{ width: 80, height: 80 }}
                className="avatar"
            />
            <span className="balance">{balance ? balance : 0} KSM</span><p className="text">{text}</p>
        </div>
    </>)
}

export default Chatbox;
