import React from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import People from "../Firebase/People";

export default class Body extends React.Component {

    render() {
        return (
            <>
            <Card className="card" sx={{ minWidth: 275, maxWidth: 450 }}>
                <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        Welcome to Commit's playground.
                    </Typography>
                    <Typography variant="h5" component="div">
                        {this.props.totalBalance && (<span> You own {this.props.totalBalance} KSM, worth ${this.props.currentValue}. </span>)}
                        {(!this.props.totalBalance || !this.props.user) && (<span>Log in and link your wallet to show off your massive bags.</span>)}
                    </Typography>
                </CardContent>
            </Card>
            <People />
            </>
        )
    }
}
