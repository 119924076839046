import './App.css';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Home from "./pages/Home";
import Chat from "./pages/Chat";
import Header from "./components/Regions/Header";
import React, { useState } from 'react';
import Button from "@mui/material/Button";
import {getPrice} from "./components/CryptoApis/Coingecko";

function App() {

    const [user, setUser] = useState(null);
    const [balance, setBalance] = useState(null);
    const [currentValue, setCurrentValue] = useState(null);

    function getUser(user) {
        setUser(user);
    }

    function getBalance(balance) {
        setBalance(balance);
    }

    getPrice('kusama', 'usd', (data) =>
        setCurrentValue((data * balance).toFixed(2)));

    return (
      <>
      <Header
          user={getUser}
          totalBalance={getBalance}
      />
      <Router>
          <div className="nav">
              <Button color="grey" variant="contained" size="small">
                  <Link to="/">Home</Link>
              </Button>
              <Button color="grey" variant="contained" size="small">
                  <Link to="/chat">Chat</Link>
              </Button>
          </div>

          <Routes>
              <Route exact path="/" element={<Home user={user} balance={balance} currentValue={currentValue} />} />
              <Route exact path="/chat" element={<Chat balance={balance} />} />
          </Routes>
      </Router>
      </>
    );
}

export default App;
