import React from "react";
import Body from "../components/Regions/Body";
import {Helmet, HelmetProvider} from "react-helmet-async";

export default class Home extends React.Component {

    render() {
        return (
            <HelmetProvider>
                <Helmet>
                    <title>Commit</title>
                    <meta name="description" content="Web & Mobile Engineering" />
                </Helmet>
                <Body
                    totalBalance={this.props.balance}
                    user={this.props.user}
                    currentValue={this.props.currentValue}
                />
            </HelmetProvider>
        )
    }
}
