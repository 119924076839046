import React from "react";
import Auth from "../Firebase/Auth";
import PolkaWallet from "../Polkadot/PolkaWallet";
import {connect} from "../Firebase/Storage";

export default class Header extends React.Component {

    constructor() {
        super();
        this.getUser = this.getUser.bind(this);
        this.getWallet = this.getWallet.bind(this);
        this.getTotalBalance = this.getTotalBalance.bind(this);
        this.state = {
            data: "",
            user: null,
            wallet: null,
            totalBalance: 0
        };
    }

    componentDidUpdate() {
        if(this.state.user) {
            connect(this.state.user, this.state.totalBalance);
        }
    }

    getUser(user) {
        this.setState({user: user});
        this.props.user(user);
    };

    getWallet(wallet) {
        this.setState({wallet: wallet});
    };

    getTotalBalance(totalBalance) {
        this.setState({totalBalance: totalBalance});
        this.props.totalBalance(totalBalance);
    };

    render() {
        return (
            <div className="header">
                <PolkaWallet wallet={this.getWallet} totalBalance={this.getTotalBalance} />
                <Auth user={this.getUser} />
            </div>
        )
    }
}
