import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import config from './config/firebase';
import React from "react";
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import {disconnect} from "./Storage";

firebase.initializeApp(config);

const uiConfig = {
    signInFlow: 'popup',
    signInOptions: [{
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        requireDisplayName: false
    },
        firebase.auth.GoogleAuthProvider.PROVIDER_ID
    ],
};

const auth = getAuth();

export default class Auth extends React.Component {

    constructor() {
        super();
        this.state = {user: null};
        this.signOut = this.signOut.bind(this);
    }

    componentDidMount() {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                this.setState({user: user});
            } else {
                this.setState({user: null});
            }
            this.props.user(user);
        });
    }

    signOut() {
        disconnect(this.state.user);
        auth.signOut();
    }

    render() {
        if(this.state.user === null) {
            return (
                <div>
                    <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth}/>
                </div>
            )
        } else {
            return (
                <div className="user">
                    <Avatar
                        alt={this.state.user.displayName}
                        src={this.state.user.photoURL}
                        sx={{ width: 50, height: 50 }}
                    />
                    <Button color="grey" variant="contained" size="small" onClick={this.signOut}>Sign out</Button>
                </div>
            )
        }
    }

}
