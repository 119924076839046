import React from "react";
import {getData, setData} from "./Storage";
import Avatar from "@mui/material/Avatar";

export default class Poeple extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            people: []
        };
        this.setLog = this.setLog.bind(this);
    }

    setLog() {
        var today = new Date();
        var date = today.getFullYear()+'-'+(("0" + (today.getMonth()+1)).slice(-2))+'-'+("0"+today.getDate()).slice(-2);
        var time = ("0"+today.getHours()).slice(-2) + ":" + ("0"+today.getMinutes()).slice(-2) + ":" + ("0"+today.getSeconds()).slice(-2);
        var dateTime = date+' '+time;
        setData('/'+this.props.user.uid+'/'+dateTime, {
            time: dateTime,
            dot: this.props.totalBalance + " DOT",
            value: "$" + this.props.currentValue
        });
        this.setState({needsUpdate: true})
    }

    componentDidMount() {
        getData('/users', (data) =>
            this.setState({people: data}));
        this.setState({needsUpdate: false})
    }

    render() {
        if(this.state.people) {
            var people = Object.values(this.state.people).reverse();
        } else {
            people = [];
        }
        return (
                <div className="people-container">
                    <h3>Now online</h3>
                    <div className="people">
                    {people.map((person) => {
                        return(
                            <div key={person.uid} className="person">
                            <Avatar
                                alt={person.uid}
                                src={person.photoURL}
                                sx={{ width: 50, height: 50 }}
                                className="avatar"
                            />
                            <span className="balance">{person.balance} KSM</span>
                            </div>
                        )
                    })}
                    </div>
                </div>
        )
    }

}
