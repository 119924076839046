export var getPrice = function(from, to, _callback) {
    fetch("https://api.coingecko.com/api/v3/simple/price?ids="+from+"&vs_currencies="+to)
        .then(res => res.json())
        .then(
            (result) => {
                _callback(`${result[from][to].toFixed(2)}`);
            },
            (error) => {
                console.log(error)
            }
        )
}
