import React from "react";
import {
  ApiPromise,
  WsProvider
} from '@polkadot/api';
import {
  web3Accounts,
  web3Enable
} from '@polkadot/extension-dapp';
import Button from '@mui/material/Button';
import SelectAddress from "./SelectAddress";

export default class PolkaWallet extends React.Component {

  constructor() {
    super();
    this.connect = this.connect.bind(this);
    this.setCurrentAddress = this.setCurrentAddress.bind(this);
  	this.state = {
  	  api: null,
      currentAddress: "",
      addresses: [],
      balances: [],
      totalBalance: 0,
      wallet: false,
    };
  }

  async componentDidMount() {
    const provider = "wss://kusama-rpc.polkadot.io";
    const wsProvider = new WsProvider(provider);
    const api = new ApiPromise({ provider: wsProvider });
    await api.isReady;
    this.setState({api: api});

    await this.connect('load');
  }

  async subscribeToChange(address, api) {

    // Retrieve the initial balance. Since the call has no callback, it is simply a promise
    // that resolves to the current on-chain value
    await api.query.system.account(address);

    // Here we subscribe to any balance changes and update the on-screen value
    api.query.system.account(address, ({ data: { free: currentFree }, nonce: currentNonce }) => {

      const decimals = 1000000000000;
      const balance = currentFree / decimals;
      this.setState(oldState => ({
        balances: {
          [address]: balance.toFixed(2),
          ...oldState.balances
        }
      }));
      let totalBalance = 0;
      for (let value of Object.entries(this.state.balances)) {
        totalBalance += parseFloat(value[1]);
      }
      this.setState({
        totalBalance: totalBalance
      });
      this.props.totalBalance(totalBalance);
    });

  }

  setCurrentAddress(address) {
    if(address !== this.state.currentAddress) {
      this.setState({
        currentAddress: address
      });
      this.props.wallet(address);
    }
  }

  async connect(source) {

    const extension = await web3Enable('Commit!');
    if(extension.length > 0) {
      const allAccounts = await web3Accounts();

      if (allAccounts.length > 0) {

        this.setCurrentAddress(allAccounts[0].address);
        this.setState({wallet: true});

        await allAccounts.map((item) =>
            this.setState({
              addresses: this.state.addresses.concat({"address": item.address, "label": item.meta.name})
            })
        );

        await allAccounts.map((item) =>
            this.subscribeToChange(item.address, this.state.api).catch(console.error)
        );

      }

    } else {
      if(source !== "load") {
        alert('Polkadot.js is not installed or permission is missing.')
      }
    }

  }

  render() {
    let wallet = this.state.wallet;

    return (
      <>
      {this.state.addresses[0] && (
        <SelectAddress addresses={this.state.addresses} currentAddress={this.setCurrentAddress} balances={this.state.balances} />
      )}
      {wallet !== true && (
        <Button size="small" color="grey" variant="contained" onClick={this.connect}>Link Polkadot.js</Button>
      )}
      </>
    )
  }
}
