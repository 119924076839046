import { initializeApp } from 'firebase/app';
import { getDatabase, ref, set, onValue, onDisconnect, remove } from "firebase/database";
import config from './config/firebase';

const app = initializeApp(config);
const db = getDatabase(app);

export var connect = function(user, balance) {
    var reference = ref(db,"users/"+user.uid);
    set(reference, {
        uid: user.uid,
        photoURL: user.photoURL,
        balance: balance
    });
    onDisconnect(reference).remove();
}

export var disconnect = function(user) {
    remove(ref(db,"users/"+user.uid));
}

export var getData = function(path, _callback) {
    onValue(ref(db, path), (data) => {
        if(data.val() !== undefined) {
            _callback(data.val());
        }
    });

};

export var setData = function(path, value) {
    set(ref(db, path), value);
}
