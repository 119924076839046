import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Identicon from "@polkadot/react-identicon";

function SimpleDialog(props) {
    const { onClose, onClickClose, open, addresses, balances, currentAddress } = props;

    const handleClose = () => {
        onClickClose();
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };



    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Choose an address</DialogTitle>
            <List sx={{ pt: 0 }}>
                {addresses.map((address) => {
                    if(currentAddress === address.address) {
                        var active = "active";
                    }
                    return (
                    <ListItem className={active} button onClick={() => handleListItemClick(address)} key={address.address}>
                        <Identicon
                                className="icon"
                                value={address.address}
                                size='32'
                                theme='kusama'
                        />
                        <ListItemText className="label" primary={address.label} />
                        <ListItemText className="balance" primary={balances[address.address]} />
                    </ListItem>
                )}
                )}
            </List>
        </Dialog>
    );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
};

export default function SelectAddress(props) {

    const { addresses, currentAddress, balances } = props;

    const [open, setOpen] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState(addresses[0]);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClickClose = () => {
        setOpen(false);
    };

    const handleClose = (value) => {
        currentAddress(value.address);
        setOpen(false);
        setSelectedValue(value);
    };

    return (
        <div>
            <Button className="wallet" color="grey" size="medium" variant="contained" onClick={handleClickOpen}>
                <Identicon
                        className="icon"
                        value={selectedValue.address}
                        size='25'
                        theme='kusama'
                />
                <span className="label">{selectedValue.label}</span>
                <span className="balance">{balances[selectedValue.address]}</span>
            </Button>
            <SimpleDialog
                selectedValue={selectedValue.address}
                open={open}
                onClose={handleClose}
                onClickClose={handleClickClose}
                addresses={addresses}
                balances={balances}
                currentAddress={selectedValue.address}
            />
        </div>
    );
}
