import React from "react";
import Chatbox from "../components/Chat/Chatbox";
import { Helmet, HelmetProvider } from "react-helmet-async";

const Chat = (props) => {

    const { balance } = props;

    return (
        <HelmetProvider>
            <Helmet>
                <title>Chat</title>
                <meta name="description" content="Web & Mobile Engineering chat" />
            </Helmet>
            <h1>Chatbox</h1>
            <Chatbox balance={balance} />
        </HelmetProvider>
    );
};

export default Chat;
